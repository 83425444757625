import { useEffect, useState } from "react";
import AllClaimableRewards from "../all-claimable-rewards/all-claimable-rewards";
import EpochTVC from "../epoch-tvc/epoch-tvc";
import "./portfolio-box.scss";
import RewardToken from "../reward-token/reward-token";

const PortfolioBox = ({userAddress, isConnected}) => {
  const [epochTVCPortfolio, setEpochTVCPortfolio] = useState();

  const getEpochTVCPortfolio = async() => {
    await fetch(process.env.REACT_APP_ORACLE_URL + "/pools/epoch-tvc-portfolio/" + userAddress.toLowerCase())
    .then(res => res.json())
    .then((data) => {
      setEpochTVCPortfolio(data);
    })
    .catch((_) => {
      // again here
    });
  }
  useEffect(() => {
    if(isConnected && epochTVCPortfolio === undefined){
      getEpochTVCPortfolio();
    }
  }, [userAddress]);

  return (
    <div className="box">
      <div className="row">
        <div className="col-12">
          <h5>Your stats</h5>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col-12">
              <p className="title">
                7day TVC{" "}
                <img
                  className="info-icon"
                  src="/assets/img/info-icon.png"
                  alt="info icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Sum of all your certified volume the last 7 days."
                />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h5>
                {userAddress
                ? (epochTVCPortfolio !== undefined
                    ? <EpochTVC amount={epochTVCPortfolio} />
                    : <>Loading…</>
                )
                : <EpochTVC amount={0} />
                }
              </h5>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12">
              <p className="title">
                Claimable Rewards{" "}
                <img
                  className="info-icon"
                  src="/assets/img/info-icon.png"
                  alt="info icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Time maximum to claim your rewards is 4 weeks."
                />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
            <h5>
              {!userAddress 
                ? <RewardToken
                    amount={0}
                    chainId={process.env.REACT_APP_CHAIN_ID}
                  />
                : <AllClaimableRewards address={userAddress}/>
              }
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioBox;
