import { memo } from "react";

const AllPoolsViewLoader = () => {
    return (
    <>
      <div className="col-12 mt-3">
        <h5 className="loading-pools text-center">
          <span>Loading pools…</span>
        </h5>
      </div>
    </>
  );
}
export default memo(AllPoolsViewLoader);