import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useAccount, useContractReads, useSigner } from "wagmi";
import { ethers } from "ethers";
import PoolABI from "../../assets/abis/pool.json";
// import { usePoolValidator } from "../../services/use-pool-validator";
import AddRewards from "../add-rewards/add-rewards";
import ApiKeyBox from "../api-key-box/api-key-box";
import Button from "../buttons/button";
import Chart from "../chart/chart";
import ClaimButton from "../buttons/claim-button";
import ClaimableRewards from "../claimable-rewards/claimable-rewards";
import DataBox from "../data-box/data-box";
import WalletCertifVol from "../epoch-certif-vol/wallet-certif-vol";
import EpochRewards from "../epoch-rewards/epoch-rewards";
import EpochTVCByPool from "../epoch-tvc-by-pool/epoch-tvc-by-pool";
import ExchangeName from "../exchange/exchange-name";
import InfoCertifyBox from "../info-certify-box/info-certify-box";
import TokenSymbol from "../token-symbol/token-symbol";
import TokenLogo from "../token-logo/token-logo";
// import HistRV from "../yields/hist-rv";
import PoolDetailLoader from "../loaders/pool-detail-loader";
import "./pool-detail.scss";
import SwitchNetworkModal from "../switch-network-modal/switch-network-modal";
import { useInvalidNetwork } from "../../services/use-invalid-network";
import Page404 from "../special-pages/page-404";
import RewardToken from "../reward-token/reward-token";
import {Helmet} from "react-helmet";

const Pool = () => {
  const [screen, setScreen] = useState("firstScreen");
  const [certifiedData, setCertifiedData] = useState({});
  const { poolAddress } = useParams();
  const { isConnected, address: userAddress } = useAccount(); 
  //IMPORTANT: useSigner && useAccount are needed two for not rerender unusefully
  const { status: signerStatus, data: signerData } = useSigner();

  const [showModal, setShowModal] = useState(false);
  const isValidAddress = ethers.utils.isAddress(poolAddress);
  const isInvalidNetwork = useInvalidNetwork();
  
  // usePoolValidator(poolAddress);
  
  const lmPoolContract = {
    address: poolAddress,
    abi: PoolABI,
    chainId: process.env.REACT_APP_CHAIN_ID,
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG,
    enabled: isValidAddress
  };
  const { data, isError, isLoading } = useContractReads({
    contracts: [
      {
        ...lmPoolContract,
        functionName: "pairTokenA",
      },{
        ...lmPoolContract,
        functionName: "pairTokenB",
      },{
        ...lmPoolContract,
        cacheTime: 0,
        functionName: "getCurrentEpoch",
      },{
        ...lmPoolContract,
        functionName: "rewardToken",
      },{
        ...lmPoolContract,
        functionName: "chainId",
      },{
        ...lmPoolContract,
        functionName: "exchange",
      },{
        ...lmPoolContract,
        functionName: "getStartDate",
      }
    ],
    enabled: !isInvalidNetwork
  });

  if(isInvalidNetwork) {
    return <SwitchNetworkModal show={isInvalidNetwork}/>
  }

  if(!
    (signerStatus!=="loading" && (
    (!signerData && !userAddress) ||
    (signerData && signerData._address === userAddress)
   ))
   ||
   isLoading || isError
  ){
    return <PoolDetailLoader/>
  }

  let [addressTokenA, addressTokenB, currentEpoch, rewardToken, chainId, exchange, startDate] = data;
  const invalidDataReaded =(
    !addressTokenA ||
    !addressTokenB ||  
    !currentEpoch ||
    !rewardToken ||
    !chainId ||
    !exchange ||
    !startDate
  );
  chainId = parseInt(chainId);
  currentEpoch = parseInt(currentEpoch);

  if(!isInvalidNetwork && (!isValidAddress || invalidDataReaded)) {
    return <Page404 />
  }

  return (
    <div className="box">
        <Helmet>
            <title>Pool detailed: {poolAddress} | Liquid miners</title>
        </Helmet>
        <div className="row mt-4">
        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 align-center">
          <TokenLogo
            customClass="pool-detail-img"
            address={addressTokenA}
            chainId={chainId}
          />
          <TokenLogo
            customClass="pool-detail-img right-img"
            address={addressTokenB}
            chainId={chainId}
          />
          <div className="pool-detail-pair pool-detail-pair-name">
            <span>
              <TokenSymbol address={addressTokenA} chainId={chainId} /> /
              <TokenSymbol address={addressTokenB} chainId={chainId} />
            </span>
          </div>
        </div>
        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12">
          <div className="row d-flex justify-content-end">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mt-2">
            {(!isLoading && isConnected)
            ? <ClaimButton
              customClass={"liquid-button liquid-button-full-height"}
              text="Claim"
              addressPool={poolAddress}
              rewardToken={rewardToken}
              currentEpoch={currentEpoch}
              isConnected={isConnected}
            />
            : (" ")
            }
            </div>
            {(exchange && exchange.toLowerCase() === "uniswap") && (
                <div className="col col-xl-4 col-lg-4 col-md-6 col-sm-12 mt-2">
                  <NavLink
                    target={"_blank"}
                    className="stats-link end"
                    to={
                      "/swap/uniswap/" +
                      addressTokenA +
                      "/" +
                      addressTokenB +
                      "/" +
                      chainId
                    }
                  >
                    <Button text="Trade" type="button-light w-100" />
                  </NavLink>
                </div>
              )
            }

            <div className="col col-xl-4 col-lg-4 col-md-6 col-sm-12 mt-2 end">
              <Button
                onClick={() => setShowModal(!showModal)}
                text="Add Rewards"
                type="button-light w-100"
              />
            </div>
          </div>
        </div>

        <div className="col-12 pl-65 mt-4">
          <ExchangeName 
            addressTokenA={addressTokenA}
            addressTokenB={addressTokenB}
            chainId={chainId}
            exchangeName={exchange}
          />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-xl-7 col-md-12 col-lg-7 col-12">
          <div className="row">
            <div className="col-12">
              <span className="pool-detail-title">Reward Pool</span>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6 col-md-2">
              <div className="row">
                <div className="col-12">
                  <p className="title">EPOCH</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  {(isLoading || isError) ? (
                    <h5>Loading…</h5>
                  ) : (
                    <h5>{currentEpoch}</h5>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="row">
                <div className="col-12">
                  <p className="title">
                    EPOCH TVC{" "}
                    <img
                      className="info-icon"
                      src="/assets/img/info-icon.png"
                      alt="info icon"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Total value certified last 7 days period."
                    />
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <h5>
                    <EpochTVCByPool
                      poolAddress={poolAddress}
                      currentEpoch={currentEpoch}
                      startDate={startDate}
                    />
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="row">
                <div className="col-12">
                  <p className="pool-detail-title-blue">EPOCH REWARD</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                <h5>
                  {(isLoading) 
                  ? <>Loading…</>
                  : <EpochRewards
                        epoch={currentEpoch.toString()}
                        rewardToken={rewardToken}
                        address={poolAddress}
                        chainId={process.env.REACT_APP_CHAIN_ID}
                      />
                  }
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 offset-md-6 col-md-6 col-lg-5 offset-lg-7 col-12 offset-xl-1">
          <div className="row">
            <div className="col-12 personal-title d-flex justify-content-end">
              <span className="pool-detail-title">Your Activity</span>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5">
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <p className="title">Weekly Cerfified</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 d-flex justify-content-end">
                  {isLoading ? (
                    <h6>Loading…</h6>
                  ) : userAddress
                  ? (<h5>
                      <WalletCertifVol
                        epoch={currentEpoch.toString()}
                        wallet={userAddress}
                        address={poolAddress}
                      />
                    </h5>) 
                  : (<h5>
                      <span className="blue">$</span>0.00
                    </h5>
                  )}
                </div>
              </div>
            </div>
            <div className="col-7">
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <p className="title">Claimable rewards</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 d-flex justify-content-end">
                  <h5>
                    {(isConnected && currentEpoch > 1)
                      ? (isLoading) 
                        ? <>Loading…</>
                        : <ClaimableRewards
                              poolAddress={poolAddress}
                              userAddress={userAddress}
                              rewardToken={rewardToken}
                              currentEpoch={currentEpoch}
                          />
                      : <RewardToken
                          amount={0}
                          address={rewardToken}
                          chainId={process.env.REACT_APP_CHAIN_ID}
                        />
                    }
                    </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-6 col-lg-6 col-12">
          {!isLoading ? (
            <Chart
              currentEpoch={currentEpoch.toString()}
              poolAddress={poolAddress}
              rewardToken={rewardToken}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="col-xl-6 col-lg-6 col-12">

            {(!isLoading && !isError && screen === "firstScreen") && (
            <InfoCertifyBox
              setScreen={setScreen}
              poolAddress={poolAddress}
              epoch={currentEpoch}
              senderAddress={userAddress}
              isConnected={isConnected}
            />
          )}

          {(!isLoading && !isError && screen === "secondScreen") && (
            <ApiKeyBox
              setScreen={setScreen}
              setCertifiedData={setCertifiedData}
              senderAddress={userAddress}
              isConnected={isConnected}
            />
          )}
          {(!isLoading && !isError && screen === "thirdScreen") && (
            <DataBox
            setScreen={setScreen} 
            certifiedData={certifiedData}
            addressTokenA={addressTokenA}
            addressTokenB={addressTokenB}
            chainId={chainId}
            isConnected={isConnected}
            key={Math.random}
              />
          )}
        </div> 
      </div>

      {(showModal && !isLoading)
       ? <AddRewards
          showModal={showModal}
          poolAddress={poolAddress}
          rewardToken={rewardToken}
          userAddress={userAddress}
        />
      : <></>
      } 
    </div>
  );
};

export default Pool;
