import "./create-reward-pool.scss";
import exchangesList from "../../assets/json/exchanges-list.json";
import factoryABI from "../../assets/abis/factory.json";
import factoryOldABI from "../../assets/abis/factory-old.json";//CARLOS: borrar cuando se cambien los contratos
import NetworksListByExchange from "../../assets/json/networks-list-by-exchange.json";
import productionRewardCurrencies from "../../assets/json/production-reward-currencies.json";
import testnetRewardCurrencies from "../../assets/json/testnet-reward-currencies.json";
import { ethers } from "ethers";
import { Navigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useState } from "react";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useSigner,
  useWaitForTransaction
} from "wagmi";
import { HandleRevertMessage } from "../error-messages/error-messages";
import SwitchNetworkModal from "../switch-network-modal/switch-network-modal";
import { useInvalidNetwork } from "../../services/use-invalid-network";
import ChainLogo from "../chain-logo/chain-logo";
import Select from "../selects/select";
import Button from "../buttons/button";
import TokenSymbol from "../token-symbol/token-symbol";
import ConnectYourWalletFirst from "../connect-your-wallet-first/connect-your-wallet-first";
import CreateRewardPoolModalDex from "./create-reward-pool-modal-dex";
import CreateRewardPoolModalCex from "./create-reward-pool-modal-cex";
import CreateRewardPoolLoader from "../loaders/create-reward-pool-loader";
import {Helmet} from "react-helmet";
import { useEffect } from "react";
import exchangeList from "../../assets/json/exchanges-list.json";

const dex = exchangeList.exchangeTypes.dex;
const cex = exchangeList.exchangeTypes.cex

const CreateRewardPool = () => {

  const [exchangeValue, setExchange] = useState("");
  const [pairTokenA, setPairTokenA] = useState("");
  const [pairTokenB, setPairTokenB] = useState("");
  const [pairChainId, setPairChainId] = useState("");
  const [rewardToken, setRewardToken] = useState("");
  const [networks, setNetworks] = useState([]);
  const [modal, setModal] = useState(false);
  const [allDataEntered, setAllDataEntered] = useState(false);
  const [formValidatedExchange, setFormValidatedExchange] = useState(false);
  const [formValidatedPair, setFormValidatedPair] = useState(false);
  const [formValidatedRewardToken, setFormValidatedRewardToken] = useState(false);
  const [lastOptionSelectedParentA, setLastOptionSelectedParentA] = useState(null);
  const [lastOptionSelectedParentB, setLastOptionSelectedParentB] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isOnMMProcess, setIsOnMMProcess] = useState(false);

  const { isConnected, address: userAddress } = useAccount(); 
  //IMPORTANT: useSigner && useAccount are needed two for not rerender unusefully
  const { status: signerStatus, data: signerData } = useSigner();

  const isInvalidNetwork = useInvalidNetwork();
  
  const Toggle = () => setModal(!modal);

  const resetForm = () => {
    setPairTokenA("");
    setPairTokenB("");
    setPairChainId("");
    setRewardToken("");
    setModal(false);
    setLastOptionSelectedParentA({});
    setLastOptionSelectedParentB({});
    setFormValidatedExchange(false);
    setFormValidatedPair(false);
    setFormValidatedRewardToken(false);
    setAllDataEntered(false);
  };
  const handleTokenChange = (token, position) => {
    if (position === "A") {
      setPairTokenA(token);
    } else {
      setPairTokenB(token);
    }
  };
  const handleExchangeChange = (exchange) => {
    resetForm();

    setExchange(exchange);
    setNetworks(NetworksListByExchange[exchange]);
    setIsOnMMProcess(false);

    if(NetworksListByExchange[exchange].length === 0) {
      setFormValidatedExchange(false);
      NotificationManager.warning("This option is not yet available", 'Warning', process.env.NOTIFICATION_TIME);
    }else{
      setFormValidatedExchange(true);
    }
  };

  const handlePairChainIdChanged = (chainId) => {
    setPairChainId(chainId);
    setPairTokenA("");
    setPairTokenB("");
    setFormValidatedPair(false);
    setAllDataEntered(false);
    setIsOnMMProcess(false);
  };

  const isTokenExistsOnNetwork = async (token) => {
    return await fetch(
      process.env.REACT_APP_ORACLE_URL + "/pools/check-token-address",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          chainId: +pairChainId,
          tokenAddress: token,
        }),
      }
    ).then(async (res) => await res.json().catch(async (_) => false));
  };
  const handleRewardsCurrencyChange = (currency) => {
    setIsOnMMProcess(false);

    if (currency === "") {
      NotificationManager.warning("Must be indicate Reward token", 'Warning', process.env.NOTIFICATION_TIME);
      setAllDataEntered(false);
    } else {
      setRewardToken(currency);
      setFormValidatedRewardToken(true);
    }
  };

  const handlePairPopup = async () => {
    setPairTokenA(pairTokenA.trim());
    setPairTokenB(pairTokenB.trim());

    const lowerPairTokenA = pairTokenA.toLowerCase();
    const lowerPairTokenB = pairTokenB.toLowerCase();

    let flag = true;
    if(pairTokenA === "" || pairTokenB === "") {
      flag = false;
      NotificationManager.error("Must be indicate two token addresses in this pool", 'Error!', process.env.NOTIFICATION_TIME);
    }
    if(flag && 
      pairTokenA === ethers.constants.AddressZero &&
      pairTokenB === ethers.constants.AddressZero
    ) {
      flag = false;
      NotificationManager.error("Sorry, These two tokens are unpairable", 'Error!', process.env.NOTIFICATION_TIME);
    }


    if(flag && pairTokenA === ethers.constants.AddressZero) {
      flag = false;
      NotificationManager.error("Sorry, but we don't have the equivalent address in layer 2 of token A", 'Error!', process.env.NOTIFICATION_TIME);
    }

    if(flag && pairTokenB === ethers.constants.AddressZero) {
      flag = false;
      NotificationManager.error("Sorry, but we don't have the equivalent address in layer 2 of token B", 'Error!', process.env.NOTIFICATION_TIME);
    }

    if(flag && lowerPairTokenA === lowerPairTokenB){
      flag = false;
      NotificationManager.error("Token addresses must be different", 'Error!', process.env.NOTIFICATION_TIME);
    }

    if (flag && !ethers.utils.isAddress(pairTokenA)) {
      flag = false;
      NotificationManager.error(pairTokenA + " is not a valid address", 'Error!', process.env.NOTIFICATION_TIME);
    }

    if (flag && !ethers.utils.isAddress(pairTokenB)) {
      flag = false;
      NotificationManager.error(pairTokenB + " is not a valid address", 'Error!', process.env.NOTIFICATION_TIME);
    }

    if (flag && !(await isTokenExistsOnNetwork(pairTokenA))) {
      flag = false;
      NotificationManager.error("Adress " + pairTokenA + " does not correspond to a valid token on the selected network.", 'Error!', process.env.NOTIFICATION_TIME);
    }
    if (flag && !(await isTokenExistsOnNetwork(pairTokenB))) {
      flag = false;
      NotificationManager.error("Adress " + pairTokenB + " does not correspond to a valid token on the selected network.", 'Error!', process.env.NOTIFICATION_TIME);
    }

    if (flag) {
      setFormValidatedPair(true);
      setPairTokenA(pairTokenA);
      setPairTokenB(pairTokenB);
      Toggle();
    }else{
      setAllDataEntered(false);
    }
  };

  const handleCancelPopup = () => {
    if(!formValidatedPair){
      setPairChainId("");
      setPairTokenA("");
      setPairTokenB("");
      setAllDataEntered(false);
    }
    Toggle();
  }
  const handleOpenModal = () => {
    setFormValidatedRewardToken(false);
    setRewardToken(false);
    Toggle();
  }

  let rewardCurrencyItems;
  if (process.env.REACT_APP_CHAIN_ID === "80001") {
    rewardCurrencyItems = testnetRewardCurrencies;
  } else {
    rewardCurrencyItems = productionRewardCurrencies;
  }
  const args = [exchangeValue, pairTokenA, pairTokenB, rewardToken, pairChainId];
  //CARLOS: mantener esta chapuza mientras se esté con el factory 0x34d5C5D3F56e309B052A707266fba0b0176E7dAE, si es diferente siempre se añadirá process.env.REACT_APP_POOL_TYPE
  if(
    process.env.REACT_APP_FACTORY_ADDRESS !== "0x34d5C5D3F56e309B052A707266fba0b0176E7dAE" &&
    process.env.REACT_APP_POOL_TYPE
  ) {
    args.push(process.env.REACT_APP_POOL_TYPE)
  }

  const { 
    config, 
    error: prepareError, 
  } = usePrepareContractWrite({
    address: process.env.REACT_APP_FACTORY_ADDRESS,
    abi: (process.env.REACT_APP_FACTORY_ADDRESS === "0x34d5C5D3F56e309B052A707266fba0b0176E7dAE") ? factoryOldABI : factoryABI, //CARLOS: borrar cuando se cambien los contratos
    functionName: "createDynamicPool",
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    args,
    enabled: allDataEntered && !isInvalidNetwork && isSubmitting,
    cacheTime: 1,
    onSettled() {
      setSubmitting(false);
    }
  });

  const {
    data,
    write,
    error: writeError,
    isError: writeIsError,
    isLoading: writeIsLoading,
    reset: writeReset,
  } = useContractWrite({
    ...config,
  });
  
  const {
    data: txData,
    isLoading: waitIsLoading,
    isSuccess: waitIsSuccess
  }
  = useWaitForTransaction({
    hash: data?.hash,
  });
  
  const clearError = () => {
    setAllDataEntered(false); 
    setExchange("");
    setNetworks([]);
    setPairChainId("")
    setPairTokenA("");
    setPairTokenB("");
    setRewardToken("");
    setFormValidatedExchange(false);
    setFormValidatedPair(false);
    setFormValidatedRewardToken(false);
    setLastOptionSelectedParentA(null);
    setLastOptionSelectedParentB(null);
  }

  useEffect(() => {
    if(prepareError && allDataEntered) {
      setAllDataEntered(false);
      setIsOnMMProcess(false);
      writeReset();
      clearError();
      return HandleRevertMessage(prepareError);
    }

  },[prepareError,allDataEntered]);

  useEffect(() => {
    if(writeIsError && allDataEntered) {
      setAllDataEntered(false);
      setIsOnMMProcess(false);
      writeReset();
      clearError();
      return HandleRevertMessage(writeError);
    }
  },[writeIsError, allDataEntered]);

  useEffect(()=>{
    if(allDataEntered && isOnMMProcess && write && isSubmitting) {
      try{        
        write();
      }catch(_){
        writeReset();
        //silence is gold
      }
    }
  },[
    write
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let flag = true;
    if (
      !formValidatedExchange ||
      !formValidatedPair ||
      !formValidatedRewardToken
    ) {      
      flag = false;
    }
    setAllDataEntered(flag);
    setIsOnMMProcess(flag);
    setSubmitting(flag);
  };

  if(isInvalidNetwork) {
    return <SwitchNetworkModal show={isInvalidNetwork}/>
  }

  if(!
    (signerStatus!=="loading" && (
    (!signerData && !userAddress) ||
    (signerData && signerData._address === userAddress)
   ))
  ){
    if(formValidatedPair || formValidatedRewardToken || formValidatedExchange){
      setAllDataEntered(false);
      setIsOnMMProcess(false);
      writeReset();
      clearError();
    }
    return <CreateRewardPoolLoader/>
  }
   
  if (!waitIsLoading && waitIsSuccess && isOnMMProcess) {
    if (txData.status === 1) {
      let poolAddress = "0x" + txData.logs[0].topics[1].substring(26);
      return <Navigate to={`/pool/${poolAddress}`} />;
    } else {
      setAllDataEntered(false);
      NotificationManager.error("Error while creating the pool, please try again", 'Error!', process.env.NOTIFICATION_TIME);
      setIsOnMMProcess(false);
      writeReset();
      clearError();
      return;
    }
  }

  let disableForm = !!waitIsLoading || !!writeIsLoading || !!isOnMMProcess;
  let exchangeTitle, rewardTitle, rewardImage;
  if(disableForm) {
    exchangesList.tags.map((el,index) => {
        if(el.key === exchangeValue) {
            exchangeTitle = exchangesList.tags[index]['title'];
        }
    });

    rewardCurrencyItems.map((el,index) => {
        if(el.key === rewardToken) {
            rewardTitle = rewardCurrencyItems[index]['title'];
            rewardImage = rewardCurrencyItems[index]['image'];
        }
    });
  }

  return (
    <div className="d-flex justify-content-center">
      <Helmet>
        <title>Create rewards pool | Liquid miners</title>
      </Helmet>
      <div className="dark-box create-pool-box">
        <div className="row">
          <div className="col-12">
            <h5>New Reward Pool</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p>
              A reward pool is created by generating a new smart contract that is specific to a particular token pair on a specific exchange.
              To keep smart contract active or valid for certifying trading activity, it must have rewards for the current epoch.
            </p>
          </div>
        </div>

        <hr />
            {isConnected
                ?(<>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 center">
                        {!disableForm
                        ?(
                            <Select
                                onChange={handleExchangeChange}
                                title="Select an Exchange…"
                                items={exchangesList.tags}
                                defaultValue={exchangeValue}
                            />
                        ):(
                            <span className="span-disabled">{exchangeTitle}</span>
                        )
                        }
                
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 center">
                            {formValidatedExchange && (
                                !disableForm
                                ? (
                                    <button 
                                        className="pair-btn" 
                                        onClick={handleOpenModal}
                                    >
                                        {!formValidatedPair 
                                        ? (<>Create a Pair…</>)
                                        : (
                                            <>
                                                <TokenSymbol address={pairTokenA} chainId={pairChainId} noLink={true}/> /
                                                <TokenSymbol address={pairTokenB} chainId={pairChainId} noLink={true}/>
                                                {dex.includes(exchangeValue) && (
                                                    <ChainLogo chainId={pairChainId} />
                                                )}
                                            </>
                                        )}
                                    </button>
                                ):(
                                    <span className="span-disabled">
                                        <TokenSymbol address={pairTokenA} chainId={pairChainId} noLink={true}/> /
                                        <TokenSymbol address={pairTokenB} chainId={pairChainId} noLink={true}/>
                                        {dex.includes(exchangeValue) && (
                                            <ChainLogo chainId={pairChainId} />
                                        )}
                                    </span>
                                )
                                
                            )}
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 center">
                            {formValidatedExchange && formValidatedPair && (
                                !disableForm
                                    ? (
                                        <Select
                                            onChange={handleRewardsCurrencyChange}
                                            title="Select Reward Currency…"
                                            items={rewardCurrencyItems}
                                        />
                                    ):(
                                        <>

                                        <div className="span-disabled">{rewardTitle}{" "}
                                          <img 
                                            src={rewardImage} 
                                            width="20px" alt={rewardTitle} />
                                        </div>
                                        </>
                                    )
                                )
                            }
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                            <div className="col-12 end">
                            { 
                                (
                                    formValidatedExchange &&
                                    formValidatedPair &&
                                    formValidatedRewardToken
                                ) && (disableForm 
                                    ? <Button
                                        disabled={true}
                                        text="Creating Pool..."
                                        type="button-disabled"
                                    />
                                    : <Button
                                        onClick={handleSubmit}
                                        text="Create Pool"
                                        type="button-light"
                                    />
                                )
                            }
                        </div>
                    </div>
                </>)
                :(
                <div className="row">
                    <ConnectYourWalletFirst noMargins={true}/>
                </div>
            )
        }
        </div>
        {dex.includes(exchangeValue) && (
          <CreateRewardPoolModalDex
              handleCancelPopup={handleCancelPopup}
              handlePairPopup={handlePairPopup}
              handlePairChainIdChanged={handlePairChainIdChanged}
              handleTokenChange={handleTokenChange}
              networks={networks}
              modal={modal}
              pairChainId={pairChainId}
              pairTokenA={pairTokenA}
              pairTokenB={pairTokenB}
              isConnected={isConnected}
          />
        )}

        {cex.includes(exchangeValue) && (
          <CreateRewardPoolModalCex
              handleCancelPopup={handleCancelPopup}
              handlePairPopup={handlePairPopup}
              modal={modal}
              setPairTokenA={setPairTokenA}
              setPairTokenB={setPairTokenB}
              setPairChainId={setPairChainId}
              setLastOptionSelectedParentA={setLastOptionSelectedParentA}
              setLastOptionSelectedParentB={setLastOptionSelectedParentB}
              lastOptionSelectedParentA={lastOptionSelectedParentA}
              lastOptionSelectedParentB={lastOptionSelectedParentB}
              setFormValidatedPair={setFormValidatedPair}
              isConnected={isConnected}
          />
        )}
    </div>
  );
};

export default CreateRewardPool;
