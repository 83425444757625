import { gql, useApolloClient } from "@apollo/client";
import { useState } from "react";

const allPoolsQuery = gql`
  query getPoolEntities($limit: Int, $offset: Int) {
    poolEntities(first: $limit, skip: $offset) {
      id
    }
  }
`;

const getPools = async (client) => {
  const limit = 1000;
  let total = 0;
  let numLastReaded = 0;
  let i = 0;
  do{
    const result = await client.query({
      query: allPoolsQuery,
      variables: {
        limit: limit,
        offset: limit * i,
      },
    });

    numLastReaded = result.data.poolEntities.length;
    
    if(!result || numLastReaded === 0) {
      break;
    }
    total += numLastReaded;
    i++;
  }while(numLastReaded === limit);
  return total;
};

const usePoolCount = () => {
  const [poolsCounter, setPoolCounter] = useState(null);
  const client = useApolloClient();
  if(poolsCounter === null) {
    getPools(client)
    .then((data) => {
      setPoolCounter(data)
    })
    .catch((_) => {
      setPoolCounter(0)
    });
  }
    
  return { data: poolsCounter };
};

const AmountOfPools = () => {
  const { data } = usePoolCount();
  if (data === undefined || data === null) {
    return <>Loading...</>;
  }

  return data;
};

export default AmountOfPools;
