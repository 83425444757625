import { NavLink } from "react-router-dom";
import { useContractReads } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
// import { usePoolValidator } from "../../services/use-pool-validator";
import ClaimButton from "../buttons/claim-button";
import ClaimableRewards from "../claimable-rewards/claimable-rewards";
import DateComponent from "../basics/date-component";
import EpochCertifVol from "../epoch-certif-vol/epoch-certif-vol";
import TotalWalletCertifVol from "../epoch-certif-vol/total-wallet-certif-vol";
import EpochRewards from "../epoch-rewards/epoch-rewards";
import ExchangeName from "../exchange/exchange-name";
import TokenSymbol from "../token-symbol/token-symbol";
import TokenLogo from "../token-logo/token-logo";
import exchangeList from "../../assets/json/exchanges-list.json";
// import DailyYield from "../yields/daily-yield";
// import HistRV from "../yields/hist-rv";
import PoolBoxLoader from "../loaders/pool-box-loader"
import "./pool-box.scss";
import RewardToken from "../reward-token/reward-token";

const PoolBox = ({
  addressPool,
  addressTokenA,
  addressTokenB,
  chainId,
  endRewardsDate,
  exchangeName,
  userAddress
}) => {
  const lmPoolContract = {
    address: addressPool,
    abi: PoolABI,
    chainId: process.env.REACT_APP_CHAIN_ID
  };

  // usePoolValidator(addressPool);

  const { 
    data, 
    isLoading,
  } = useContractReads({
    contracts: [
      {
        ...lmPoolContract,
        functionName: "getCurrentEpoch",
      },
      {
        ...lmPoolContract,
        functionName: "rewardToken",
      }
    ],
    enabled: addressPool,
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT,
  });


  if(isLoading || !data[0] || !data[1]) {
    return (
      <PoolBoxLoader/>
    );
  }
  
  let epochStr = '1';
  let rewardTokenStr = ''
  if(!isLoading && data.length > 0) {
      epochStr = data[0];
      rewardTokenStr = data[1];
  }

  return (
    <div className="pool-box">
      <div className="row">
        <div className="col-xl-4 col-12 pl-5">
          <div className="row mt-3">
            <div className="col-6">
              <div className="row">
                <div className="col-xl-3 col-lg-2 col-md-3 col-sm-3 col-3 ">
                  <TokenLogo address={addressTokenA} chainId={chainId} />
                </div>
                <div className="col-9">
                  <h6 className="pair-name">
                    <TokenSymbol 
                      address={addressTokenA} 
                      chainId={chainId} 
                      noLink={(!exchangeList.exchangeTypes.dex.includes(exchangeName))}
                    /> /
                    <TokenSymbol 
                      address={addressTokenB} 
                      chainId={chainId}
                      noLink={(!exchangeList.exchangeTypes.dex.includes(exchangeName))}
                      />
                  </h6>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-2 col-md-1 col-sm-1 col-1"></div>
                <div className="col-9">
                  <div className="cex-name">
                    <ExchangeName
                      addressTokenA={addressTokenA}
                      addressTokenB={addressTokenB}
                      chainId={chainId}
                      exchangeName={exchangeName}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-12 text-center align-self-end">
                  <p className="blue-title m-1">Epoch Rewards</p>
                </div>
                <div className="col-12 text-center">
                  <h5>
                  {isLoading 
                  ? <>Loading…</>
                  : <EpochRewards
                      epoch={epochStr}
                      rewardToken={rewardTokenStr}
                      address={addressPool}
                      chainId={process.env.REACT_APP_CHAIN_ID}
                    />
                  }
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <TokenLogo address={addressTokenB} chainId={chainId} />
            </div>
          </div>
        </div>

        <div className="col-xl-1 col-12 separator-container">
          <hr className="separator" />
        </div>

        <div className="col-xl-5 col-12">
          <div className="row">

            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 col-4 pt-5">
              <div className="row">
                <div className="col-12 center">
                  <p>Epoch Certif. Vol</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 center">
                  <h6>
                    {isLoading 
                    ? <>Loading…</>
                    : <EpochCertifVol
                        epoch={epochStr}
                        address={addressPool}
                      />
                    }
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 col-4 pt-5">
              <div className="row">
                <div className="col-12 center">
                  <p>Your Certif. Vol</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 center">
                  <h6>
                    {isLoading 
                    ? <>Loading…</>
                    : userAddress 
                      ? <TotalWalletCertifVol
                          wallet={userAddress}
                          address={addressPool}
                        />
                      : <><span className="blue">$</span>0.00</>
                    }
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 col-4 pt-5">
              <div className="row">
                <div className="col-12 center">
                  <p>End Date</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 center">
                  <div className="badge">
                    {endRewardsDate !== "0" 
                    ? <DateComponent timestamp={endRewardsDate} />
                    : <span> - </span>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-1 col-12 m-0 separator-container">
          <hr className="separator" />
        </div>
        <div className="col-xl-2 col-12 part3">
          <div className="row">
            <div className="col-6 pt-5">
              <div className="row">
                <div className="col-12 center">
                  <p>Claim</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 center ">
                  <h6>
                    {userAddress
                    ? isLoading 
                        ? <>Loading…</>
                        : <ClaimableRewards
                            poolAddress={addressPool}
                            userAddress={userAddress}
                            currentEpoch={epochStr}
                            rewardToken={rewardTokenStr}
                          />
                    : <RewardToken 
                        amount={0}
                        address={rewardTokenStr}
                        chainId={process.env.REACT_APP_CHAIN_ID}
                      />
                    }
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-6 align-self-end">
              <div className="row">
                <div className="col-12 center">
                  <NavLink className="stats-link" to={"/pool/" + addressPool}>
                    <button className="pool-box-button">Go to pool</button>
                  </NavLink>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 center">
                {userAddress && (
                  <ClaimButton
                    text="Claim"
                    customClass="liquid-button"
                    rewardToken={rewardTokenStr}
                    currentEpoch={parseInt(epochStr)}
                    addressPool={addressPool}
                    isConnected={!!userAddress}
                  />
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolBox;
