import { useState } from "react";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import Button from "../buttons/button";
import Modal from "../modal/modal";

function Wallet() {
  const errorMsgStyle = {
    color: "red",
  };
  const [modal, setModal] = useState(false);
  const Toggle = () => setModal(!modal);
  const DisconnectWallet = () => {
    disconnect();
    Toggle()
  };
  const { address, isConnected } = useAccount();
  const { connect, connectors, error } = useConnect();
  const { disconnect } = useDisconnect();

  if (isConnected) {
    localStorage.setItem('addressConnected', address);
    return (
      <>
        <Button
          text={showAddress(address)}
          type="button-light"
          onClick={() => Toggle()}
        />
        <Modal show={modal} close={() => Toggle()} title="Disconnect Wallet" textButton="Close">
          <div className="row">
            <div className="col-12 text-center mt-3">
              <Button
                text="Disconnect wallet"
                type="button-light"
                onClick={() => DisconnectWallet()}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }

  localStorage.removeItem('addressConnected');

  return (
    <>
      <Button
        text="Connect wallet"
        type="button-light"
        onClick={() => Toggle()}
      />
      <Modal show={modal} close={() => Toggle()} title="Connect Wallet" textButton="Close">
        <div className="row">
          {connectors.map((connector) => {
            return (
              <div key={connector.id} className="col-12 text-center mt-3">
                <Button
                  text={connector.name}
                  type="button-light button-connector"
                  onClick={() => {
                    connect({ connector });
                    setModal(!modal);
                  }}
                />
              </div>
            );
          })}
        </div>

        {error && (
          <div className="mt-4 text-center" style={errorMsgStyle}>
            <h6>{error.message}</h6>
          </div>
        )}
      </Modal>
    </>
  );
}

const showAddress = (address) => {
  return (
    address.substring(0, 5) +
    "…" +
    address.substring(address.length, address.length - 5)
  );
};

export default Wallet;
