import "./info-certify-box.scss";
import { useContractReads } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
import ConnectYourWalletFirst from "../connect-your-wallet-first/connect-your-wallet-first";
import DateComponent from "../basics/date-component";

const InfoCertifyBox = ({ 
  setScreen, 
  poolAddress, 
  epoch,
  senderAddress, 
  isConnected 
}) => {
  const config = {
    address: poolAddress,
    abi: PoolABI,
    chainId: process.env.REACT_APP_CHAIN_ID,
  };
  const { data, isLoading } = useContractReads({
    contracts: [
      {
        ...config,
        functionName: "getProofTimeInverval",
        args: [epoch, senderAddress]
      },{
        ...config,
        functionName: "getEpochEnd",
        args: [epoch]
      },{
        ...config,
        functionName: "isActive"
      }
    ],
    enabled: !!senderAddress,
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG,
  });

  let isActive;
  let startDate = 0;
  let endDate = 0;

  if (
    !isLoading && 
    Array.isArray(data) && 
    data.length === 3 && 
    data[0] !== null && 
    data[1] !== null &&
    data[2] !== null
  ) {
    isActive = data[2];

    startDate = parseInt(data[0].start.toString());
    endDate = (parseInt(data[1].toString()) + 172800);
  }

  if(isConnected) {
    return (
      <>
        <div className="box variable-screen">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div
                className={
                  window.screen.width <= 400 ? "box w-100 mt-4" : "box w-80 mt-4"
                }
              >
                <div className="row">
                  <div className="col-12 text-center">
                    <p className="blue-title">Last Proof</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    {(!isLoading)? (
                      <div className="badge">
                        <DateComponent timestamp={startDate} key={Math.random()}/>
                      </div>
                    ) : (
                      <div className="badge">Loading…</div>
                    )}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 text-center">
                    <p className="blue-title">Next certification should be max</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    {(!isLoading) ? (
                      <div className="badge">
                        <DateComponent timestamp={endDate} key={Math.random()}/>
                      </div>
                    ) : (
                      <div className="badge">Loading…</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-center">
          {isActive ? (

                <button
                  onClick={() => setScreen("secondScreen")}
                  className="blue-dark-button"
                >
                  START CERTIFYING
                </button>

          ) : (
            <p className="w-70">
              Cannot verify trades at this moment because there are no rewards for this epoch
            </p>
          )}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 d-flex justify-content-center text-center">
              <p className="w-70">
                Remember, the beauty of Liquid Miners is that we never keep your
                API keys, they are stored on your local device, we use Zero
                Knowledge to prove your records. Learn more
                <a
                  className="link"
                  href="https://liquid-miners.gitbook.io/liquid-miners-docs/protocol-information/trading-proof"
                >
                  {" "}
                  here
                </a>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  } else {
  return (
    <div className="box variable-screen">
      <ConnectYourWalletFirst/>
    </div>
  );
}
};

export default InfoCertifyBox;
