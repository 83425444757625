import { Divide as Hamburger } from "hamburger-react";
import { memo, useState } from "react";
import { NavLink } from "react-router-dom";
import Wallet from "../wallet/wallet.jsx";
import "./navbar.scss";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <header>
      <nav className="navbar navbar-light navbar-expand-md bg-faded justify-content-center mb-5">
        <NavLink className="navbar-brand d-flex w-10 me-auto" to="/">
          <img src="/assets/img/logo-min.svg" alt="logo" />
        </NavLink>

        <button
          className="hamburguer-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsingNavbar"
        >
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </button>
        <div className="navbar-collapse collapse w-90" id="collapsingNavbar">
          <ul className="navbar-nav w-100 justify-content-start">
            <NavLink className="nav-item nav-link" to="/">
              Reward Pools
            </NavLink>
            <NavLink className="nav-item nav-link" to="/createPool">
              Create Reward Pools
            </NavLink>
            <NavLink className="nav-item nav-link" to="/faucet">
              TST Token Faucet
            </NavLink>
            <a
              className="nav-item nav-link"
              target="_blank"
              href="https://app.powerbi.com/view?r=eyJrIjoiYmQ4NGZmYzUtMDUyOS00MmFkLWFmNWMtNjE1OGY0ZGY3ODUwIiwidCI6IjM1MmE0YmFjLWY5NDktNDg0Mi04ZTAwLWU3MmIwNjhjYjAwZCIsImMiOjl9"
              rel="noreferrer noopener"
            >
              Analytics
            </a>
          </ul>
          <ul className="nav navbar-nav ms-auto justify-content-end">
            {/*  <li className="nav-item">
              <div className="nav-link">
                <Button
                  image="/assets/img/favicon.ico"
                  size="20px"
                  text=" $1,25"
                  type="button-dark"
                />
              </div>
            </li> */}
            <li className="nav-item">
              <div className="nav-link">
                <Wallet />
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default memo(Navbar);
