import { ethers } from "ethers";
import { memo, useEffect, useState } from "react";

const polygonTokenToEthList = {
  '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174': '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F': '0xdAC17F958D2ee523a2206206994597C13D831ec7'
}

const TokenLogo = ({ customClass = "token-image", address, chainId, size }) => {
  if(size === "small"){
    customClass = "token-image-small";
  }
  const [urlImageToken, setUrlImageToken] = useState(null);
  
  const checkImage = (path) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve({ success: true, path });
      img.onerror = () => resolve({ success: false, path });

      img.src = path;
    });

  if (!address) address = "0x0000000000000000000000000000000000000000";
  address = ethers.utils.getAddress(address);
  chainId = Number(chainId);
  if (chainId === 137 && Object.keys(polygonTokenToEthList).includes(address)) {
    chainId = 1;
    address = polygonTokenToEthList[address];
  }

  let chain = "ethereum";

  if (chainId === 56) {
    chain = "smartchain";
  } else if (chainId === 137) {
    chain = "polygon";
  }
  const pathImg =
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/" +
    chain +
    "/assets/" +
    address +
    "/logo.png";

  useEffect(() => {
    checkImage(pathImg).then((imgRes) => {
      setUrlImageToken(
        imgRes.success
        ? pathImg
        : "https://res.cloudinary.com/sushi-cdn/image/fetch/f_auto,fl_sanitize,q_auto,w_96/https://raw.githubusercontent.com/sushiswap/icons/master/token/unknown.png"
        );
    });
  }, [pathImg]);

  if(urlImageToken) {
    return <img className={customClass} src={urlImageToken} alt={address} />
  }

  return <>...</>

};

export default memo(TokenLogo);