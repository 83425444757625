import React, { memo, useState } from "react";
import AllPools from "../all-pools/all-pools";
import Select from "../selects/select";
import "./pools-container.scss";
import TokenSelect from "../selects/token-select";
import { constants } from "ethers";
import filters from "../../assets/json/filters.json";
const { exchangeFiltersList, sortingFiltersList, stateFiltersListConst } = filters;
 
const PoolsContainer = ({userAddress, isConnected}) => {
  let stateFiltersList = stateFiltersListConst;

  const [tokenAddressesA, setTokenAddressesA] = useState(null);
  const [tokenAddressesB, setTokenAddressesB] = useState(null);
  const [exchange, setExchange] = useState(exchangeFiltersList[0].key);
  const [state, setState] = useState(stateFiltersList[0].key);
  const [sorting, setSorting] = useState(sortingFiltersList[0].key);
  const [ allPoolsRenderKey, setAllPoolsRenderKey ] = useState(Math.random);

  if(!isConnected) {
    stateFiltersList = stateFiltersList.filter((el) => (el.key !== "participatedInPools" && el.key !== "poolsCreatedByMe"));
  }
  const handleTokenAddresses = (tokenAddresses, selectId) => {
    let tokenAddressesArray = null;

    if(tokenAddresses !== null) {
      tokenAddressesArray = Object.keys(tokenAddresses).map(
          (key) => tokenAddresses[key]
      );
      if(tokenAddressesArray[0] === "" || (
        (
          !tokenAddresses['ethereum'] &&
          !tokenAddresses['polygon-pos'] &&
          !tokenAddresses['binance-smart-chain']
        )
      )) {
          tokenAddressesArray = [constants.AddressZero];
      }
    }

    if(selectId === 'A') {
      setTokenAddressesA(tokenAddressesArray);
      setAllPoolsRenderKey(Math.random())
    }else if(selectId === 'B') {
      setTokenAddressesB(tokenAddressesArray);
      setAllPoolsRenderKey(Math.random())
    }
  }

  const handleExchangeFilterChange = (event) => {
    setExchange(event);
    setAllPoolsRenderKey(Math.random());
  }
  const handleStateFilterChange = (event) => {
    setState(event);
    setAllPoolsRenderKey(Math.random())
  }
  const handleSortingFilterChange = (event) => {
    setSorting(event);
    setAllPoolsRenderKey(Math.random())
  }

  return (
    <div className="box">
      <div className="row justify-center">
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 pt-20">
          <TokenSelect
          handleTokenAddresses={(tokenAddresses) => handleTokenAddresses(tokenAddresses, 'A')}
          placeholder={"Type Token A…"}/>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 pt-20">
          <TokenSelect
            handleTokenAddresses={(tokenAddresses) => handleTokenAddresses(tokenAddresses, 'B')}
            placeholder={"Type Token B…"}
          />
        </div>

        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 pt-20">
          <Select
            onChange={handleExchangeFilterChange}
            title={exchangeFiltersList[0].title}
            items={exchangeFiltersList}
            defaultValue={exchangeFiltersList[0].key}
          />
        </div>

        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 pt-20">
          <Select
            onChange={handleStateFilterChange}
            title={stateFiltersList[0].title}
            items={stateFiltersList}
            defaultValue={state}
          />
        </div>

        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 pt-20">
          <Select
            onChange={handleSortingFilterChange}
            title={sortingFiltersList[0].title}
            items={sortingFiltersList}
            defaultValue={sortingFiltersList[0].key}
          />
        </div>
      </div>

      <div className="row mt-4">
        <AllPools
          key={allPoolsRenderKey}
          tokenAddressesA={tokenAddressesA}
          tokenAddressesB={tokenAddressesB}
          exchange={exchange}
          state={state}
          sorting={sorting}
          timestamp={parseInt(Date.now() / 1000)}
          userAddress={userAddress}
        />
      </div>
    </div>
  )
};

export default memo(PoolsContainer);
