import "../token-symbol/token-symbol.scss";
import getRewardTokenData from "../../services/get-reward-token-data";
import { memo } from "react";

const RewardTokenSymbol = ({ address, chainId, noLink }) => {
  const blockExplorerUrls = JSON.parse(process.env.REACT_APP_BLOCK_EXPLORER_URLS);
  return (
    <span className="blue">
      {" "}
      {!noLink 
      ? (
        <a 
          href={blockExplorerUrls.chainId[chainId] + address}
          target="_blank"
          className="token-symbol-link"
          rel="noreferrer noopener"
        >{getRewardTokenData(address).symbol}</a>
      ):(
        <>{getRewardTokenData(address).symbol}</>
      )}
    </span>
  )
};

export default memo(RewardTokenSymbol);