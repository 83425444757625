import { useContractRead } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
import RewardToken from "../reward-token/reward-token";

const EpochRewards = ({ epoch, rewardToken, address, chainId }) => {
  const { data: rewards, isLoading } = useContractRead({
    address,
    abi: PoolABI,
    functionName: "getRewardsPerEpoch",
    chainId,
    args: [epoch],
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT,
  });

  if (isLoading) {
    return <>Loading…</>
  }
  return (
    <RewardToken 
    amount={rewards}
    address={rewardToken}
    chainId={process.env.REACT_APP_CHAIN_ID}
  />
  );
};

export default EpochRewards;
