import { erc20ABI, useContractReads } from "wagmi";
import { memo } from "react";

const Init = () => {

  //Save reward token data on localStorage
  const rewardTokenContract = {
    address: process.env.REACT_APP_REWARD_TOKEN_ADDRESS_DEFAULT,
    abi: erc20ABI,
    chainId: process.env.REACT_APP_CHAIN_ID,
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG,
  }
  const { data, isLoading: isLoadingDecimals } = useContractReads({
    contracts: [
      {
        ...rewardTokenContract,
        functionName: "decimals"
      },{
        ...rewardTokenContract,
        functionName: "symbol",
      },
    ],
    enabled: !localStorage.getItem("rewardTokensData"),
    onSuccess(data){
      let rewardTokens = {};
      rewardTokens[process.env.REACT_APP_REWARD_TOKEN_ADDRESS_DEFAULT] = {
        decimals: data[0],
        symbol: data[1],
      };
      localStorage.setItem("rewardTokensData", JSON.stringify(rewardTokens));
    }
  });

  if(isLoadingDecimals || !data) {
    return;
  }
  //
}

export default memo(Init);