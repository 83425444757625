export default function getRewardTokenData(address) {
    let data = localStorage.getItem("rewardTokensData");
    if(!data) {
        data = {};
        data[process.env.REACT_APP_REWARD_TOKEN_ADDRESS_DEFAULT] = {
            decimals: parseInt(process.env.REACT_APP_DEFAULT_NUM_DECIMALS),
            symbol: process.env.REACT_APP_REWARD_TOKEN_SYMBOL_DEFAULT
        }
        data = JSON.stringify(data);
    }

    const rewardToken = JSON.parse(data);

    let response;
    if(!address){
        address = Object.keys(rewardToken)[0];
    }

    response = rewardToken[address];
    response.address = address;
    
    return response;
}

export function calculateRewardTokenAmount(amount, address) {
    return parseFloat(
        (amount || 0).toString()
    ) / 10  ** getRewardTokenData(address).decimals;
}
