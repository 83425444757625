import { erc20ABI, useContractReads } from "wagmi";
import { useSymbolParser } from './use-symbol-parser';
import "./token-symbol.scss";
import { memo } from "react";

const TokenSymbol = ({ address, chainId, noLink }) => {
  const blockExplorerUrls = JSON.parse(process.env.REACT_APP_BLOCK_EXPLORER_URLS);
  let { data, isLoading } = useContractReads({
    contracts: [
      {
        address,
        abi: erc20ABI,
        chainId: parseInt(chainId),
        functionName: "symbol",
      }
    ],
    allowFailure: true,
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG
  });
  const symbol = useSymbolParser((data && data[0] !== null) ? data[0] : '??');

  if (isLoading) {
    return <>...</>;
  }

  return (
    <>
      {" "}
      {!noLink 
      ? (
        <a 
          href={blockExplorerUrls.chainId[chainId] + address}
          target="_blank"
          className="token-symbol-link"
          rel="noreferrer noopener"
        >{symbol}</a>
      ):(
        <>{symbol}</>
      )}
    </>
  )
};

export default memo(TokenSymbol);