import { useEffect, useState } from "react";
import { ethers } from 'ethers';
import "./add-rewards.scss";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useContractReads,
  erc20ABI,
  useSwitchNetwork,
  useNetwork,
} from "wagmi";
import factoryABI from "../../assets/abis/factory.json";
import Modal from "../modal/modal";
import TokenLogo from "../token-logo/token-logo";
import Button from "../buttons/button";
import Wallet from "../wallet/wallet";
import { NotificationManager } from 'react-notifications';
import { HandleRevertMessage } from "../error-messages/error-messages";
import NumberComponent from "../basics/number-component";
import RewardTokenSymbol from "../reward-token/reward-token-symbol";
import { calculateRewardTokenAmount } from "../../services/get-reward-token-data";

const AddRewards = ({ 
  showModal, 
  poolAddress, 
  rewardToken,
  userAddress
}) => {
  const [rewardsAmount, setRewardsAmount] = useState("");
  const [numOfEpoch, setNumOfEpoch] = useState("");
  const [modal, setModal] = useState(showModal);
  const [addRewardButtonClick, setAddRewardButtonClick] = useState(false);
  const [approveButtonClick, setApproveButtonClick] = useState(false);
  const [isOnMMProcess, setIsOnMMProcess] = useState(false);
  const [allDataEntered, setAllDataEntered] = useState(false);
  const [haveAllowance, setHaveAllowance] = useState(false);
  const [amountAllowanceBN, setAmountAllowanceBN] = useState(ethers.BigNumber.from('0'));
  const [amountAvailable, setAmountAvailable] = useState(0);

  const Toggle = () => setModal(!modal);
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const rewardTokenContract = {
    address: rewardToken,
    abi: erc20ABI,
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
  };

  const { data: tokenRewardData, isLoading: tokenRewardDataLoading } =
    useContractReads({
      contracts: [
        {
          ...rewardTokenContract,
          functionName: "allowance",
          args: [
            userAddress ? userAddress : (ethers.constants.AddressZero), 
            process.env.REACT_APP_FACTORY_ADDRESS
          ],
        },
        {
          ...rewardTokenContract,
          functionName: "decimals",
          cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG,
        },
        {
          ...rewardTokenContract,
          functionName: "symbol",
          cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG,
        },
        {
          ...rewardTokenContract,
          functionName: "balanceOf",
          args: [userAddress ? userAddress : ethers.constants.AddressZero],
        },
      ],
      watch: true,
      onSuccess(data) {
        setAmountAllowanceBN(ethers.BigNumber.from(data[0]));
        setAmountAvailable(calculateRewardTokenAmount(data[3],rewardToken));
      },
    });

  const MAX_INT =
    "115792089237316195423570985008687907853269984665640564039457584007913129639935";

  const { config: approveConfig } =
    usePrepareContractWrite({
      address: rewardToken,
      abi: erc20ABI,
      functionName: "approve",
      args: [process.env.REACT_APP_FACTORY_ADDRESS, MAX_INT],
      enabled: 
        rewardsAmount !== "" && 
        typeof rewardsAmount === 'number' && 
        rewardsAmount > 0
    });

  const {
    data: approveData,
    write: approveWrite,
    error: writeApproveError,
    isSuccess: approveIsSuccess,
    reset: approveReset,
    isError: approveIsError
  } = useContractWrite({
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    ...approveConfig,
  });

  const { isLoading: approveTxIsLoading, isSuccess: approveSuccess } =
    useWaitForTransaction({
      hash: approveData?.hash,
      confirmations: 3,
    });

  const { config: addRewardsConfig } =
    usePrepareContractWrite({
      address: process.env.REACT_APP_FACTORY_ADDRESS,
      abi: factoryABI,
      functionName: "addRewards",
      args: [
        poolAddress, 
        (rewardsAmount && rewardsAmount > 0)
        ? ethers.utils.parseEther(rewardsAmount.toString()).toString()
        : '0',
        numOfEpoch
      ],
      cacheTime: 0,
      enabled: haveAllowance
    });

  const {
    data: addRewardsData,
    write: addRewardsWrite,
    error: writeAddRewardsError,
    reset: writeReset,
    isError: addRewardsIsError,
  } = useContractWrite({
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    enabled: addRewardButtonClick && approveSuccess,
    ...addRewardsConfig,
  });

  const { isLoading: addRewardsTxIsLoading, isSuccess: addRewardsSuccess } =
    useWaitForTransaction({
      hash: addRewardsData?.hash,
    });

  const handleClose = (_) => {
    try{
      writeReset();
    }catch(_){}
    Toggle();
  }

  const handleAmountChange = (e) => {
    setAddRewardButtonClick(false);
    let formattedRewardsAmount = "";
    if(e.target.value !== ""){
      const splitText = e.target.value.replace(/[^0-9.]/g, '').split('.');
      formattedRewardsAmount = splitText[0];
      if(splitText.length > 1) {
        formattedRewardsAmount += '.';
        if(splitText[1]) {
          formattedRewardsAmount += splitText[1].substring(
            process.env.REACT_APP_DEFAULT_NUM_DECIMALS,
            0
          );
        }
      }

    }

    setRewardsAmount(formattedRewardsAmount);
  }

  const handleNumOfEpochChange = (e) => {
    setNumOfEpoch(e.target.value.replace(/[^0-9]+/g, ""));
    setAddRewardButtonClick(false);
  }

  const handleAddRewardSubmitted = (e) => {
    e.preventDefault();

    let flag = true;
    const rewardsAmountFloat = parseFloat(rewardsAmount || 0);
   
    if(numOfEpoch === "" || rewardsAmount === "" || rewardsAmount === ".") {
      flag = false;
      NotificationManager.error(
        "Must be fill all the fields.", 
        'Error!', 
        process.env.NOTIFICATION_TIME
      );
    }

    if(flag && numOfEpoch <= 0) {
      flag = false;
      NotificationManager.error(
        "Num. of Epoch must be greater than 0.", 
        'Error!', 
        process.env.NOTIFICATION_TIME
      );
    } 

    if(flag && numOfEpoch > 90) {
      flag = false;
      NotificationManager.error(
        "Can't send more than 90 epochs at the same time", 
        'Error!', 
        process.env.NOTIFICATION_TIME
      );
    }

    if(flag && rewardsAmountFloat <= 0){
      flag = false;
      NotificationManager.error(
        "Rewards amount must be greater than 0.", 
        'Error!', 
        process.env.NOTIFICATION_TIME
      );
    }

    if(flag && 
      amountAllowanceBN.lte(
        ethers.utils.parseEther(rewardsAmountFloat.toString() ?? '0')
      )
    ) {
      flag = false;
      NotificationManager.error(
        "Insufficient allowance. Must increase it with new approval.", 
        'Error!', 
        process.env.NOTIFICATION_TIME
      );
    }

    if(flag && parseFloat(amountAvailable) < rewardsAmountFloat) {
      flag = false;
      NotificationManager.error(
        "Amount entered is larger than your available tokens",
        'Error!', 
        process.env.NOTIFICATION_TIME
      );
    }

    if(flag) {
      setAddRewardButtonClick(true);
      setHaveAllowance(true);
    }else {
      setHaveAllowance(false);
      setAddRewardButtonClick(false);
    }
  }

  const handleApproveSubmitted = (e) => {
    e.preventDefault();
    setRewardsAmount(parseFloat(rewardsAmount));

    let flag = true;

    if(!rewardsAmount) {
      flag = false;
    }
    if(flag && !isNaN(rewardsAmount)){
      setApproveButtonClick(true);
    }
  }

  // use effects
  useEffect(() => {
    if(addRewardsWrite && addRewardButtonClick) {
      addRewardsWrite();
      setAddRewardButtonClick(false);
      setIsOnMMProcess(true);
    }
  }, [addRewardButtonClick, addRewardsWrite]);

  useEffect(() => {
    if(approveWrite && approveButtonClick) {
      approveWrite();
      setApproveButtonClick(false);
      setIsOnMMProcess(true);
    }
  }, [approveButtonClick, approveWrite]);

  useEffect(() => {
    setAllDataEntered(rewardsAmount !=="" && numOfEpoch !== "");
  }, [rewardsAmount, numOfEpoch]);

  //conditional behaviors
  //add rewards
  if(addRewardsSuccess && isOnMMProcess) {
    setAddRewardButtonClick(false);
    setIsOnMMProcess(false);
    setHaveAllowance(false);
    writeReset();
    NotificationManager.success("Rewards added succesfully", 'Done!', process.env.NOTIFICATION_TIME);
    Toggle();
    return;
  }

  if(addRewardsIsError && numOfEpoch && rewardsAmount) {
    setAddRewardButtonClick(false);
    setIsOnMMProcess(false);
    setHaveAllowance(false);
    writeReset();
    HandleRevertMessage(writeAddRewardsError);
  }

  //approve
  if(approveIsSuccess && isOnMMProcess) {
    setApproveButtonClick(false);
    setIsOnMMProcess(false);
    setHaveAllowance(false);
    setAmountAllowanceBN(ethers.BigNumber.from(MAX_INT))
  }

  if(approveIsError && rewardsAmount) {
    setApproveButtonClick(false);
    setIsOnMMProcess(false);
    setHaveAllowance(false);
    approveReset();
    HandleRevertMessage(writeApproveError);
  }

  //rendering
  return (
    <Modal
      show={modal}
      close={handleClose}
      title="Add rewards to pool"
      textButton="Close"
      hideButton={addRewardsTxIsLoading || approveTxIsLoading || isOnMMProcess}
    >
      <div className="row">
        <div className="col-12 text-center mt-3">
          <div className="row">
            <div className="col-12">
              <h3>
                <TokenLogo
                  customClass="pool-detail-img-big"
                  address={rewardToken}
                  chainId={process.env.REACT_APP_CHAIN_ID}
                />
                <span className="white">
                  <RewardTokenSymbol
                    address={rewardToken}
                    chainId={process.env.REACT_APP_CHAIN_ID}
                  />
                </span>
              </h3>
            </div>
          </div>
          {!tokenRewardDataLoading ? (
            <div className="row">
              <div className="col-12">
                <h6>
                  Available:{" "}
                  <NumberComponent num={amountAvailable}/>
                  {" " + tokenRewardData[2]}
                </h6>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row mt-4">
            <div className="col-12">
              {!userAddress ? (
                <></>
              ) : chain.id.toString() !== process.env.REACT_APP_CHAIN_ID ? (
                <Button
                  text="Change to correct Network"
                  type="button-light"
                  onClick={() =>
                    switchNetwork?.(Number(process.env.REACT_APP_CHAIN_ID))
                  }
                />
              ) : (isOnMMProcess || approveTxIsLoading || addRewardsTxIsLoading) ? (
                <>
                  <input
                    className="add-rewards-input-disabled"
                    type="text"
                    value={rewardsAmount}
                    disabled={true}
                  />
                </>
              ) : (
                <>
                <input
                  className="create-pool-input"
                  type="text"
                  value={rewardsAmount}
                  placeholder="Amount"
                  maxLength="59"
                  onChange={handleAmountChange}
                />
              </>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              {(!tokenRewardDataLoading &&
                rewardsAmount &&
                (calculateRewardTokenAmount(tokenRewardData[0], rewardToken) >= parseFloat(rewardsAmount) ||
                  approveSuccess)
                  ) && (
                    (isOnMMProcess || approveTxIsLoading || addRewardsTxIsLoading) ? (
                    <input
                      className="add-rewards-input-disabled"
                      type="text"
                      value={numOfEpoch}
                      disabled={true}
                    />
                    ) : (
                    <input
                      className="create-pool-input"
                      type="text"
                      value={numOfEpoch}
                      placeholder="Num. of epochs"
                      maxLength="2"
                      onChange={handleNumOfEpochChange}
                    />
                  ))
                }
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
            {
                (() => {
                    if(userAddress && chain.id.toString() === process.env.REACT_APP_CHAIN_ID) {
                        if(!tokenRewardDataLoading) {
                            if(amountAvailable < parseFloat(rewardsAmount ?? 0)) {
                                return (
                                    <Button text="Insufficient funds" type="button-disabled" />
                                )
                            }else if(
                                (parseInt(tokenRewardData[0].toString()) /
                                10 ** tokenRewardData[1]) < rewardsAmount 
                                &&
                                !approveSuccess) {
                                if(approveTxIsLoading) {
                                    return (
                                        <Button text="Approving…" type="button-disabled" />
                                    )
                                }else if(isOnMMProcess) {
                                    return (
                                        <Button text="Confirm the action on your wallet…" type="button-disabled" />
                                    )
                                }else {
                                    return (
                                        <Button
                                        //   onClick={() => approveWrite?.()}
                                          onClick={handleApproveSubmitted}
                                          text={`Approve ${tokenRewardData[2]}`}
                                          type="button-light"
                                        />
                                    )
                                }
                            } else {
                                if(addRewardsTxIsLoading) {
                                    return (
                                        <Button text="Adding rewards…" type="button-disabled" />
                                    )
                                }else if(isOnMMProcess) {
                                    return (
                                        <Button text="Confirm the action on your wallet…" type="button-disabled" />
                                    )
                                }else if(allDataEntered) {
                                    return (
                                        <Button
                                        onClick={handleAddRewardSubmitted}
                                        text="Add Rewards"
                                        type="button-light"
                                        />
                                    )
                                }else {
                                    return (
                                        <></>
                                    )
                                }
                            }
                        }else {
                            return (
                                <Button text="Loading…" type="button-disabled" />
                            )
                        }
                    }else{
                        return (
                            <Wallet />
                        )
                    }
                })()
            }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddRewards;
