import RewardTokenSymbol from "./reward-token-symbol";
import NumberComponent from "../basics/number-component";
import getRewardTokenData from "../../services/get-reward-token-data";
import { memo } from "react";
import { ethers } from "ethers";

const RewardToken = ({ 
    address, 
    chainId, 
    noLink,
    amount
 }) => {
    return (
        <>
            <NumberComponent num={ethers.utils.formatEther(amount)}
            />
            {" "}
            <RewardTokenSymbol
                address={getRewardTokenData(address).address} 
                chainId={chainId}
                noLink={noLink}
            />
        </>
    )
 }

export default memo(RewardToken);
