import { useEffect, useState } from "react";
import EpochRewardsAllPools from "../epoch-rewards-all-pools/epoch-rewards-all-pools";
import EpochTVC from "../epoch-tvc/epoch-tvc";
import AmountOfPools from "../pool-amount/pool-amount";
import "./platform-box.scss";

const PlatformBox = () => {
  const [epochTVCPlatform, setEpochTVCPlatform] = useState();
  useEffect(() => {
    if(epochTVCPlatform === undefined) {
      fetch(process.env.REACT_APP_ORACLE_URL + "/pools/epoch-tvc-platform")
        .then((res) => res.json())
        .then((data) => {
          setEpochTVCPlatform(data);
        })
        .catch((_) => {
          // nothing more to say
        });
    }
  }, [epochTVCPlatform]);

  return (
    <div className="box">
      <div className="row">
        <div className="col-12">
          <h5>Platform</h5>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col-12">
              <p className="title">
                Weekly TVC{" "}
                <img
                  className="info-icon"
                  src="/assets/img/info-icon.png"
                  alt="info icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Total volume certified this week."
                />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h5>
              {epochTVCPlatform === undefined 
                ? <>Loading…</>
                : <EpochTVC amount={epochTVCPlatform} />
              }
              </h5>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12">
              <p className="title">Pools</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h5>
                <AmountOfPools />
              </h5>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12">
              <p className="title">Weekly Rewards</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h5>
                <EpochRewardsAllPools />
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformBox;
