import Modal from "../modal/modal";
import { ethers } from "ethers";
import { HandleRevertMessage } from "../error-messages/error-messages";
import Button from "../buttons/button";

const SwitchNetworkModal = ({show}) => {
  const changeNetwork = async () => {
    const hexChainId = ethers.utils.hexValue(Number(process.env.REACT_APP_CHAIN_ID));
    if (window.ethereum) {
      try {
        await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexChainId }],
        });
      } catch (errorSwitching) {
        return (
          HandleRevertMessage(errorSwitching)
        )
      }
    }
  }

  if(show) {
    return (
      <Modal
        show={true}
        title="Check your network"
        textButton="Close"
        hideButton={true}
        close={()=>{}}
      >
        <div className="row">
          <div className="col-12 text-center mt-3">
            <div className="row">
              <div className="col-12">
                <h5 class="white">
                  Currently we just work on Polygon network.
                </h5>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <Button
                    text="Switch your network"
                    type="button-light"
                    onClick={changeNetwork}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default SwitchNetworkModal;
