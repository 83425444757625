import { memo } from "react";
import "./button.scss";

const Button = (props) => {
  const { text, type, onClick, image, size , id, disabled} = props;

  if (image) {
    return (
      <button className={type} onClick={onClick} disabled={disabled}>
        <img width={size} src={image} alt="img" />
        {text}
      </button>
    );
  } else {
    return (
      <button id={id} className={type} onClick={onClick} disabled={disabled}>
        {text}
      </button>
    );
  }
};

export default memo(Button);
