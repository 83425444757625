export function useSymbolParser(symbol) {
  if (!symbol) {
    return symbol;
  }
  if (symbol.toLowerCase() === "wbtc") {
    symbol = "BTC";
  } else if (symbol.toLowerCase() === "weth") {
    symbol = "ETH";
  } else if (symbol.toLowerCase() === "wbnb") {
    symbol = "BNB";
  } else if (symbol.toLowerCase() === "wmatic") {
    symbol = "MATIC";
  }

  return symbol;
}
