import CreateRewardPoolModal from "./create-reward-pool-modal";
import TokenLogo from "../token-logo/token-logo";
import TokenSymbol from "../token-symbol/token-symbol";
import Select from "../selects/select";

import { ethers } from "ethers";

//TODO: Verificar que las addresses tengan la función symbol (o sea que son ERC20)
const CreateRewardPoolModalDex = ({
    handleCancelPopup,
    handlePairPopup,
    handlePairChainIdChanged,
    handleTokenChange,
    networks,
    modal,
    pairChainId,
    pairTokenA,
    pairTokenB,
    isConnected
}) => {

    return (
        <CreateRewardPoolModal
        show={modal}
        save={handlePairPopup}
        cancel={handleCancelPopup}
        >
            <div className="row">
                <div className="col-12 text-center mt-3">
                    {networks.length > 0 && (
                    <Select
                        onChange={handlePairChainIdChanged}
                        title="Chain…"
                        items={networks}
                        defaultChecked={pairChainId}
                    />
                    )}
                </div>
            </div>
            {pairChainId ? (
            <>
                <div className="row">
                    <div className="col-12 text-center mt-3">
                        <input
                        maxLength="42"
                        placeholder="Address token A"
                        value={pairTokenA}
                        onChange={(e) => handleTokenChange(e.target.value, "A")}
                        type="text"
                        className="create-pool-input"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center mt-3">
                        <input
                        maxLength="42"
                        placeholder="Address token B"
                        value={pairTokenB}
                        onChange={(e) => handleTokenChange(e.target.value, "B")}
                        type="text"
                        className="create-pool-input"
                        />
                    </div>
                </div>
            </>
            ) : (
            <></>
            )}

            <div className="row mt-4">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 center mt-2">
                    {pairTokenA && pairChainId ? (
                    <h4>
                        {ethers.utils.isAddress(pairTokenA) 
                            ? <TokenLogo address={pairTokenA} chainId={pairChainId} />
                            :<></>
                        }
                        <span className="white">
                        {ethers.utils.isAddress(pairTokenA) 
                            ? <TokenSymbol address={pairTokenA} chainId={pairChainId} />
                            : <></>
                        }
                        </span>
                    </h4>
                    ) : pairChainId 
                        ? (
                            <h4>
                                <TokenLogo />
                                <span className="white">Token A</span>
                            </h4>
                        ) : (
                        <></>
                        )
                    }
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 center mt-2">
                    {pairTokenB && pairChainId ? (
                    <h4>
                        {ethers.utils.isAddress(pairTokenB) 
                        ? <TokenLogo address={pairTokenB} chainId={pairChainId} />
                        : <></>
                        }

                        <span className="white">
                        {ethers.utils.isAddress(pairTokenB) 
                            ? <TokenSymbol address={pairTokenB} chainId={pairChainId} />
                            : <></>
                        }
                        </span>
                    </h4>
                    ) : pairChainId 
                    ? (
                    <h4>
                        <TokenLogo />
                        <span className="white">Token B</span>
                    </h4>
                    ) : <></>
                    }
                </div>
            </div>
        </CreateRewardPoolModal>
    )
}

export default CreateRewardPoolModalDex;
