import { SwapWidget } from '@uniswap/widgets';
import { useParams } from 'react-router-dom';
import { useInvalidNetwork } from "../../services/use-invalid-network";
import { erc20ABI, useAccount, useContractReads } from 'wagmi';
import UniswapSwapLoader from '../loaders/uniswap-swap-loader';
import SwitchNetworkModal from "../switch-network-modal/switch-network-modal";
import ConnectYourWalletFirst from "../connect-your-wallet-first/connect-your-wallet-first";

const UniswapSwap = () => {
    const { isConnected } = useAccount();
    const isInvalidNetwork = useInvalidNetwork();

    const { tokenA, tokenB, chainId } = useParams();
    const tokenAContract = {
        address: tokenA,
        abi: erc20ABI,
        chainId: Number(chainId),
        cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG
    }
    const tokenBContract = {
        address: tokenB,
        abi: erc20ABI,
        chainId: Number(chainId),
        cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG
    }

    const { data, isLoading } = useContractReads({
        contracts: [
            {
                ...tokenAContract,
                functionName: "decimals"
            },{
                ...tokenBContract,
                functionName: "decimals"
            },{
                ...tokenAContract,
                functionName: "symbol",
            },{
                ...tokenBContract,
                functionName: "symbol",
            }
        ],
        enabled: !isInvalidNetwork && isConnected && tokenA && tokenB && chainId,
        allowFailure: true,
        cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG,

    })

    if(!isConnected) {
      return (
        <div className="box">
          <div className="row mt-4">
            <div className=" col-12 center">
              <h2 className='white'>Swap your tokens on Uniswap</h2>
            </div>
            <div className="row mt-5">
              <ConnectYourWalletFirst noMargins={true}/>
            </div>
          </div>
        </div>
      );
    }

    if(isInvalidNetwork) {
        return <SwitchNetworkModal show={isInvalidNetwork}/>
    }

    if (!data || isLoading) {
        return (
            <UniswapSwapLoader/>
        );
    }

    const [decimalsA, decimalsB, symbolA, symbolB] = data;

    if(
      decimalsA === null || 
      decimalsB === null || 
      symbolA === null || 
      symbolB === null
      ) {
      return (
        <div className="box">
          <div className="row mt-4">
            <div className=" col-12 center">
              <h2 className='white'>Invalid data readed</h2>
            </div>
          </div>
        </div>
      );
    }
    const tokenList = [
        {
            "name": symbolA,
            "address": tokenA,
            "symbol": symbolA,
            "decimals": Number(decimalsA),
            "chainId": Number(chainId)
        },
        {
            "name": symbolB,
            "address": tokenB,
            "symbol": symbolB,
            "decimals": Number(decimalsB),
            "chainId": Number(chainId)
        }
    ];
    const feeRecipients = {
        1: process.env.REACT_APP_FEE_RECEIVER_ETH,
        137: process.env.REACT_APP_FEE_RECEIVER_MATIC
    }
    return (
        <div className='justify-center pt-40'>
            <SwapWidget 
                tokenList={tokenList}
                routerUrl=''
                defaultInputTokenAddress={tokenA}
                defaultOutputTokenAddress={tokenB}
                defaultChainId={chainId}
                hideConnectionUI={false}
                convenienceFee={Number(process.env.REACT_APP_UNISWAP_FEE)}
                convenienceFeeRecipient={feeRecipients}
            ></SwapWidget>
        </div>
    );
};

export default UniswapSwap;
