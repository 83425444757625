import CreateRewardPoolModal from "./create-reward-pool-modal";
import TokenSelect from "../selects/token-select";
import { useState, useEffect } from "react";
import { constants } from "ethers";

const CreateRewardPoolModalDex = ({
    handleCancelPopup,
    handlePairPopup, 
    modal,
    setPairTokenA,
    setPairTokenB,
    setPairChainId,
    setLastOptionSelectedParentA,
    setLastOptionSelectedParentB,
    lastOptionSelectedParentA,
    lastOptionSelectedParentB,
    setFormValidatedPair,
    isConnected
}) => {
    const [tokenAddressesRawA, setTokenAddressesRawA] = useState(null);
    const [tokenAddressesRawB, setTokenAddressesRawB] = useState(null);
    const [continueInParent, setContinueInParent] = useState(false);
    const [cancelInParent, setCancelInParent] = useState(false);
    const [lastOptionSelectedChildA, setLastOptionSelectedChildA] = useState({});
    const [lastOptionSelectedChildB, setLastOptionSelectedChildB] = useState({});
    
    useEffect(()=>{
        if(continueInParent) {
            setContinueInParent(false);
            handlePairPopup();
        }
    },
        [continueInParent]
    );

    useEffect(()=>{
        if(cancelInParent) {
            setCancelInParent(false);
            handleCancelPopup();
        }
    },
        [cancelInParent]
    );

    const handleTokenAddresses = (tokenAddresses, lastOptionSelected, selectId) => {
        if(selectId === 'A') {
            setLastOptionSelectedChildA(lastOptionSelected, selectId);
            if(tokenAddresses === null) {
                setTokenAddressesRawA(null);
            }else{
                setTokenAddressesRawA(
                    (
                        typeof tokenAddresses === "object" &&
                        (
                            tokenAddresses['ethereum'] ||
                            tokenAddresses['polygon-pos'] || 
                            tokenAddresses['binance-smart-chain']
                        )
                        ? tokenAddresses
                        : {ethereum: constants.AddressZero}
                    )
                );
            }
        }else if(selectId === 'B') {
            setLastOptionSelectedChildB(lastOptionSelected, selectId);
            if(tokenAddresses === null) {
                setTokenAddressesRawB(null);
            }else{
                setTokenAddressesRawB(
                    (
                        typeof tokenAddresses === "object" &&
                        (
                            tokenAddresses['ethereum'] ||
                            tokenAddresses['polygon-pos'] || 
                            tokenAddresses['binance-smart-chain']
                        )
                        ? tokenAddresses
                        : {ethereum: constants.AddressZero}
                    )
                );
            }
        }
    }

    const handlePairPopupPrev = () => {
        let flagTokenA = true;
        let flagTokenB = true;

        if(!tokenAddressesRawA) {
            flagTokenA = false;
            setPairTokenA("");
            setLastOptionSelectedChildA({});
        } 

        if(!tokenAddressesRawB) {
            flagTokenB = false;
            setPairTokenB("");
            setLastOptionSelectedChildB({});
        } 

        if(
            flagTokenA &&
            !tokenAddressesRawA['ethereum'] &&
            !tokenAddressesRawA['binance-smart-chain'] &&
            !tokenAddressesRawA['polygon-pos']
        ) {
            flagTokenA = false;
        } 

        if(
            flagTokenB &&
            !tokenAddressesRawB['ethereum'] &&
            !tokenAddressesRawB['binance-smart-chain'] &&
            !tokenAddressesRawB['polygon-pos']
        ) {
            flagTokenB = false;
        } 

        if(flagTokenA && flagTokenB) {
            if(
                tokenAddressesRawA['ethereum'] && 
                tokenAddressesRawB['ethereum']
            ) {
                setPairChainId(1);
                setPairTokenA(tokenAddressesRawA['ethereum']);
                setPairTokenB(tokenAddressesRawB['ethereum']);
            }else if(
                tokenAddressesRawA['binance-smart-chain'] && 
                tokenAddressesRawB['binance-smart-chain']
            ) {
                setPairChainId(56);
                setPairTokenA(tokenAddressesRawA['binance-smart-chain']);
                setPairTokenB(tokenAddressesRawB['binance-smart-chain']);
            }else if(
                tokenAddressesRawA['polygon-pos'] && 
                tokenAddressesRawB['polygon-pos']
            ){
                  setPairChainId(137);
                  setPairTokenA(tokenAddressesRawA['polygon-pos']);
                  setPairTokenB(tokenAddressesRawB['polygon-pos']);
            }else{
                setPairChainId(1);
                setPairTokenA(constants.AddressZero);
                setPairTokenB(constants.AddressZero);
            }

            setLastOptionSelectedParentA(lastOptionSelectedChildA);
            setLastOptionSelectedParentB(lastOptionSelectedChildB);
        }

        setContinueInParent(true);
    }

    const handleCancelPopupChild = () => {
        setLastOptionSelectedParentA({});
        setLastOptionSelectedParentB({});
        setFormValidatedPair(false);
        setCancelInParent(true);
    }

    return (
        <CreateRewardPoolModal
        show={modal}
        save={handlePairPopupPrev}
        cancel={handleCancelPopupChild}
        isConnected={isConnected}
        >
            <div className="row">
                <div className="col-12 text-center mt-3">
                    <TokenSelect 
                        handleTokenAddresses={(tokenAddresses, lastOptionSelected) => handleTokenAddresses(tokenAddresses, lastOptionSelected, 'A')}
                        placeholder={"Type Token A…"}
                        defaultValue={lastOptionSelectedParentA}
                    />
                </div>
                <div className="col-12 text-center mt-3">
                    <TokenSelect 
                        handleTokenAddresses={(tokenAddresses, lastOptionSelected) => handleTokenAddresses(tokenAddresses, lastOptionSelected, 'B')}
                        placeholder={"Type Token B…"}
                        defaultValue={lastOptionSelectedParentB}
                    />
                </div>
            </div>
        </CreateRewardPoolModal>
    )
}

export default CreateRewardPoolModalDex;
