import { useContractReads } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
import { gql, useQuery } from "@apollo/client";
import { ethers } from "ethers";
import RewardToken from "../reward-token/reward-token";

const AllClaimableRewards = ({ address }) => {
  address = address.toLowerCase();
  const pointsLast4Weeks = gql`
    query getPointsMintedEntities(
      $user: String
      $timestamp1Week: Int
      $timestamp5Weeks: Int
    ) {
      pointsMintedEntities(
        where: {
          user: $user
          created_lte: $timestamp1Week
          created_gte: $timestamp5Weeks
        }
      ) {
        pool
        epoch
      }
    }
  `;

  const timestamp1Week = parseInt(Date.now() / 1000);
  const timestamp5Weeks = parseInt(Date.now() / 1000 - (604800 * 4));

  let { data: last4Weeks, loading: last4WeeksLoading } = useQuery(
    pointsLast4Weeks,
    {
      variables: {
        user: address,
        timestamp1Week,
        timestamp5Weeks,
      },
    }
  );

  const lmContracts = [];

  last4Weeks?.pointsMintedEntities.map((item) => {
    lmContracts.push(
      {
        address: item.pool,
        abi: PoolABI,
        functionName: "pendingReward",
        chainId: process.env.REACT_APP_CHAIN_ID,
        args: [address, item.epoch],
        cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT,
      },
      {
        address: item.pool,
        abi: PoolABI,
        chainId: process.env.REACT_APP_CHAIN_ID,
        functionName: "rewardToken",
        cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG,
      }
    );
  });

  const { data: dataPools, isLoading: isLoadingPools } = useContractReads({
    contracts: lmContracts,
    enabled: last4Weeks && !last4WeeksLoading && lmContracts.length > 0
  }); 

  if(!last4Weeks || last4WeeksLoading || isLoadingPools) {
    return <>Loading…</>
  }

  if(
    last4Weeks.pointsMintedEntities.length === 0 || 
    !Array.isArray(dataPools) ||
    dataPools.length === 0
  ){
    return (
        <RewardToken
        amount={0}
        chainId={process.env.REACT_APP_CHAIN_ID}
      />
    )  
  }

  let rewards = ethers.BigNumber.from('0');
  let rewardToken = '';
  let flagOddEven = true;
  dataPools.map((el) => {
    flagOddEven =! flagOddEven;
    if(flagOddEven){
      rewardToken = el
    }else{
      rewards = rewards.add(el);
    }
  });

  return (
    <RewardToken
      amount={rewards}
      address={rewardToken} 
      chainId={process.env.REACT_APP_CHAIN_ID}
    />
  )
};

export default AllClaimableRewards;
