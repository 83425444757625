import React, { useEffect, useState } from "react";
import RewardToken from "../reward-token/reward-token";

const EpochRewardsAllPools = () => {
  const [ rewards, setRewards ] = useState();

  useEffect(() => {
    if(!rewards) {
      const url = process.env.REACT_APP_ORACLE_URL + "/pools/epoch-rewards-platform";
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        }
      })
      .then(async(res) => {
        if(res){
          return await res.json()
        }else{
          return undefined;
        }
      }
      
      )
      .then(data => {
        if(typeof data ==='object' && !data.statusCode){
          setRewards(data)
        }else{
          setRewards()
        }
      })
      .catch(_ => {
        setRewards();
        console.log(_);
      })
    }
  },
    [rewards]
  );

  if(
    rewards && 
    typeof rewards === 'object' &&
    Object.keys(rewards).length > 0 &&
    Object.values(rewards).length > 0
  ){
    return (
      <RewardToken
        address={Object.keys(rewards)[0]}
        amount={Object.values(rewards)[0]}
        chainId={process.env.REACT_APP_CHAIN_ID}
      />
    ) 
  }else{
    return (
      <RewardToken
        amount={0}
        chainId={process.env.REACT_APP_CHAIN_ID}
      />
    )
  }
};

export default EpochRewardsAllPools;
